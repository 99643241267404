var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    { staticClass: "justify-content-center" },
    [
      _c(
        "CCol",
        { staticClass: "c-col", attrs: { col: "11", md: "12", lg: "11" } },
        [
          _c(
            "CCard",
            {
              staticClass: "ccard shadow-none",
              attrs: { color: "second-card-bg" },
            },
            [
              _c(
                "CCardBody",
                { staticClass: "ccard-body" },
                [
                  _c("div", { staticClass: "cheader" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.homepage_url } },
                      [_c("logo-wrap", { attrs: { auth: true } })],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mr-3 cursor-pointer",
                            on: {
                              click: function ($event) {
                                _vm.darkMode = !_vm.darkMode
                              },
                            },
                          },
                          [
                            _vm.darkMode
                              ? _c("CIcon", { attrs: { name: "cil-sun" } })
                              : _c("CIcon", { attrs: { name: "cil-moon" } }),
                          ],
                          1
                        ),
                        _c("CSelect", {
                          staticClass: "mb-0",
                          attrs: {
                            value: _vm.locale,
                            options: _vm.$store.state.localeOptions,
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.locale = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("h1", { staticClass: "title text-white" }, [
                    _vm._v(_vm._s(_vm.$t("formRecovery.title"))),
                  ]),
                  _c("p", { staticClass: "subtitle text-muted" }, [
                    _vm._v(_vm._s(_vm.$t("formRecovery.setNewPassword"))),
                  ]),
                  _c(
                    "CForm",
                    {
                      staticClass: "cform",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("CInput", {
                        staticClass: "cinput",
                        attrs: {
                          placeholder: _vm.$t("formRecovery.new"),
                          type: "password",
                          "is-valid": _vm.detectErrorField(_vm.errors.password),
                          "invalid-feedback": _vm.errors.password,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-content",
                            fn: function () {
                              return [
                                _c("CIcon", {
                                  attrs: { name: "cil-lock-locked" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _c("CInput", {
                        staticClass: "cinput",
                        attrs: {
                          placeholder: _vm.$t("formRecovery.repeat"),
                          type: "password",
                          "is-valid": _vm.detectErrorField(
                            _vm.errors.password_confirmation
                          ),
                          "invalid-feedback": _vm.errors.password_confirmation,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-content",
                            fn: function () {
                              return [
                                _c("CIcon", {
                                  attrs: { name: "cil-lock-locked" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.passwordConfirmation,
                          callback: function ($$v) {
                            _vm.passwordConfirmation = $$v
                          },
                          expression: "passwordConfirmation",
                        },
                      }),
                      _c(
                        "CRow",
                        { staticClass: "subactions" },
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "12", xl: "6" } },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "cbtn shadow-none",
                                  attrs: { block: "", color: "dark" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.go(-1)
                                    },
                                  },
                                },
                                [_vm._v("Назад")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { col: "12", xl: "6" } },
                            [
                              _c(
                                "CLoadingButtonCustom",
                                {
                                  staticClass: "cbtn shadow-none",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    type: "submit",
                                    loading: _vm.loadingRequest,
                                    disabled: _vm.loadingRequest,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("formRecovery.reset")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }